import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconHourGlass = () => (
  <svg
    className={css.iconSvg}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.10415 17.2188C4.63123 17.2188 0.989563 13.5771 0.989563 9.10418C0.989563 4.63126 4.63123 0.989594 9.10415 0.989594C13.5771 0.989594 17.2187 4.63126 17.2187 9.10418C17.2187 13.5771 13.5771 17.2188 9.10415 17.2188ZM9.10415 2.17709C5.2804 2.17709 2.17706 5.28834 2.17706 9.10418C2.17706 12.92 5.2804 16.0313 9.10415 16.0313C12.9279 16.0313 16.0312 12.92 16.0312 9.10418C16.0312 5.28834 12.9279 2.17709 9.10415 2.17709Z"
      fill="#292D32"
    />
    <path
      d="M17.4167 18.0104C17.2663 18.0104 17.1158 17.955 16.9971 17.8363L15.4138 16.2529C15.1842 16.0233 15.1842 15.6433 15.4138 15.4138C15.6433 15.1842 16.0233 15.1842 16.2529 15.4138L17.8363 16.9971C18.0658 17.2267 18.0658 17.6067 17.8363 17.8363C17.7175 17.955 17.5671 18.0104 17.4167 18.0104Z"
      fill="#292D32"
    />
  </svg>
);

export default IconHourGlass;
